import { defineStore } from 'pinia';

export const useCheckedItemStore = defineStore('checkedItem', {
    state: () => ({
        checkedItems: new Set(), // Using a Set to store unique IDs
    }),
    actions: {
        addCheckedItem(id) {
            this.checkedItems.add(id);
        },
        removeCheckedItem(id) {
            this.checkedItems.delete(id);
        },
        isChecked(id) {
            return this.checkedItems.has(id);
        },
        clearCheckedItems() {
            this.checkedItems.clear();
        },
    },
})