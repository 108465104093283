<script setup>
// import { ref, onMounted } from 'vue'
import axios from 'axios';
import {onMounted, computed, ref} from 'vue';
import { useRouter } from 'vue-router';
import { useModeStore } from '../store/mode.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useCheckedItemStore } from '@/store/checkedItem.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();

import DocumentsCard from '../components/DocumentsCard.vue'
import TabList from '../components/TabsComponent.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';
import DocumentSkeletonCard from "@/components/DocumentSkeletonCard.vue";
import CustomPagination from '../components/CustomPagination.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';
import AddAgainConfirmation from '../components/AddAgainConfirmation.vue';

const router = useRouter();
const modeStore = useModeStore();
const activePageStore = useActivePageStore();
const modeStrokeColor = getModeStrokeColor();
const addToPlaylistStore = useAddToPlaylistStore();
const checkedItemStore = useCheckedItemStore();
const notificationTopBarStore = useNotificationTopBarStore();
const currentPage = ref(1);
const itemsPerPage = ref(12);
const totalItems = ref(0);
const user = ref({});
const isUserDetailsLoading = ref(true);
const isGridDisplayPattern = ref(true);
const isLoading = ref(false);
const activeTab = ref('0'); // we use 0 here, as podcast_categories using IDs instead of label.
const podcastCategories = ref([]);
const podcastLists = ref([]);

const changeDisplayPattern = () => {
    isGridDisplayPattern.value = !isGridDisplayPattern.value;
};

function callUserApi() {
    // get api/v1/user
    isUserDetailsLoading.value = true;
    axios.get('api/v1/user').then(response => {
        user.value = response.data;
        // console.log('loaded user data', user.value);

        console.log('user group', user.value.media_group[0].name);

    }).catch(error => {
        console.error('Failed to fetch user data:', error);
    }).finally(() => {
        isUserDetailsLoading.value = false
    });
}

const fetchPodcasts = () => {
  isLoading.value = true;
  axios.get('api/v1/asset-broadcasts', {
    params: {
      status: 1,
      content_type: 'Video',
      podcast_category: activeTab.value,
      is_podcast: true,
      per_page: itemsPerPage.value,
      order_by: 'latest',
      page: currentPage.value
    }
  }).then(response => {
    //documentList.value = response.data.data;
    podcastLists.value = response.data.data.map(document => ({
      ...document,
      isHovered: false
    }));

    totalItems.value = response.data.total;
  }).catch(error => {
    console.error('Failed to fetch documents:', error);
  }).finally(() => {
    isLoading.value = false;
  });
}

const showingFrom = computed(() => {
  return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
  return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
  return Math.ceil(totalItems.value / itemsPerPage.value);
});

const changePage = (page) => {
  currentPage.value = page;
  fetchPodcasts();
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const fetchFilterData = () => {
  axios.get('api/v1/asset-broadcasts/filters')
      .then(response => {
        // Update the filter options with the received data
        const tempPodcastsCategories = response.data.podcastCategories;
        podcastCategories.value = tempPodcastsCategories.map((category) => {
          return {
            value: category.id,
            label: category.name
          }
        });
        // add all at first index.
        podcastCategories.value.unshift({
          value: '0',
          label: 'All'
        });
      })
      .catch(error => {
        console.error('Failed to fetch filter data:', error);
      });
};

const podcastBannerImage = computed(() => {
    return require('../assets/Images/Views/PodcastPage/podcast_banner_img_cropped_compressed.png')
});
const podcastMobileBannerImage = computed(() => {
    return require('../assets/Images/Views/PodcastPage/podcast_mobile_banner.png')
});

// const documentsCardImage = computed(() => {
//     return require('../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png')
// });

onMounted(() => {
    callUserApi()
    fetchFilterData()
    activePageStore.setSelectedActivePage(7)
    fetchPodcasts();
});

const goToDocument = (id) => {
    router.push({ name: 'Podcast Details', params: { id: id } });
}

const showBarAndUpdateSelectedFile = (id) => {
    const checkbox = document.getElementById('checkbox-' + id);
    
    if (checkbox && checkbox.checked) {
        checkedItemStore.addCheckedItem(id);
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(true);
        addToPlaylistStore.setSelectedAssetBroadcastIds([...addToPlaylistStore.selectedAssetBroadcastIds, id]);
    } else { //unchecked
        checkedItemStore.removeCheckedItem(id);
        addToPlaylistStore.setSelectedAssetBroadcastIds(addToPlaylistStore.selectedAssetBroadcastIds.filter(fileId => fileId !== id));
    }

    console.log('selectedAssetBroadcastIds', addToPlaylistStore.selectedAssetBroadcastIds);
}

const addAnyWayToPlaylist = () => {
    if (addToPlaylistStore.selectedPlaylistIds && addToPlaylistStore.selectedPlaylistIds.length > 0) {
        let playlistId = addToPlaylistStore.selectedPlaylistIds[0];
        axios.post(`api/v1/user-playlists/${playlistId}/asset-broadcasts`, {
            assets: addToPlaylistStore.selectedAssetBroadcastIds.map((id, index) => ({
                asset_id: id,
                order: (index + 1)
            }))
        })
        .then((response) => {
            console.log('Request completed successfully', response);
            addToPlaylistStore.isShowAddAgainConfirmation = false;
            notificationTopBarStore.playlistIdUsedInAction = addToPlaylistStore.selectedPlaylistIds[0];
            cancelAddToPlaylist();
            addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
            notificationTopBarStore.notificationType = 'addAssetsToPlaylist';
            notificationTopBarStore.notificationMessage = `Ditambah Semula ke '<strong>${response.data.user_playlist_name}</strong>'`;
            notificationTopBarStore.idsUsedInAction = addToPlaylistStore.selectedAssetBroadcastIds;
            notificationTopBarStore.setIsShowNotificationTopBar(true);
            checkedItemStore.clearCheckedItems();
        })
        .catch((error) => {
            console.error('Error in the request', error);
        })
        .finally(() => {
            addToPlaylistStore.setIsAddingToPlaylistLoading(false);
        });
    } else {
        console.log('No playlist selected');
        addToPlaylistStore.isShowAddAgainConfirmation = false;
        cancelAddToPlaylist();
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
    }
}

const cancelAddToPlaylist = () => {
    addToPlaylistStore.setSelectedPlaylistIds([]);
    addToPlaylistStore.setShowAddToPlaylistDropdown(false);

    const radios = document.querySelectorAll("input[name='playlist-radio']");
    radios.forEach(radio => {
        radio.checked = false;
    });
}

</script>

<template>
    <div>
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <AddOrCreatePlaylistTopBar v-show="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-show="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="false"/>
        <AddAgainConfirmation v-if="addToPlaylistStore.isShowAddAgainConfirmation" @addanyway-to-playlist="addAnyWayToPlaylist" :title="addToPlaylistStore.selectedPlaylistName"/>
        <!-- banner -->
        <div v-show="isUserDetailsLoading" class="flex items-center justify-center w-full gap-2 p-6">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>

        <div v-show="!isUserDetailsLoading" id="step-4" class="relative rounded-xl md:p-[30px] my-4 h-[250px] md:h-[350px] md:dark-mode-dashboard-banner" :class="{'text-black bg-transparent': modeStore.selectedMode === 'light', 'text-white bg-transparent': modeStore.selectedMode === 'dark'}">
            <div class="grid md:grid-cols-4 h-full">
                <!--left side-->
                <div class="md:col-span-2 md:my-auto">
                    <div class="p-[30px] md:p-0">
                        <h2 class="text-xl md:text-3xl">{{ t('podcastPage.welcome')}} </h2>
                        <div class="text-3xl md:text-6xl font-bold py-1" v-if="user && user.media_group && user.media_group.length > 0">Pelajar {{ user.media_group[0].name }}</div>
                        <div class="hidden md:flex text-base uppercase py-2">
                            <p v-if="user.role_name === 'Murid' && user.media_group?.length > 0" class="pr-2">{{ user.media_group[0].name }}</p>
                            <p class="pl-2 border-l" v-if="user.school"> {{ user.school.name }}</p>
                        </div> 
                        <div class="flex-col md:hidden text-base uppercase py-2">
                            <p v-if="user.role_name === 'Murid' && user.media_group?.length > 0">{{ user.media_group[0].name }}</p>
                            <p v-if="user.school"> {{ user.school.name }}</p>
                        </div>
                    </div>
                    <div class="md:hidden min-w-full">
                        <div class="relative">
                            <img :src="podcastMobileBannerImage" alt="welcome user card background" class="object-fill max-h-[350px] min-w-full"> 
                        </div>
                    </div>
                </div>
                <!--right side-->
                <div class="col-span-3">
                    <div class="hidden md:block absolute top-0 right-0 h-full " >
                        <div class="relative">
                            <img :src="podcastBannerImage" alt="welcome user card background" class="object-fill rounded-xl max-h-[350px]"> 
                        </div>
                    </div>    
                </div>
            </div>
        </div>

        <section 
            class="rounded-xl p-[30px] bg-transparent mt-[320px] md:mt-0"
            :class="{'bg-transparent text-black': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >
            <div class="flex justify-between justify-items-center my-auto pb-2">
                <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('sideMenu.Podcast')}}</h2>
                <div class="my-auto h-10">
                    <div class="flex gap-1 justify-between h-10 my-auto">
                        <div @click="changeDisplayPattern()" class="hidden md:block w-8 my-auto cursor-pointer">
                            <!-- <img v-if="isGridDisplayPattern" class="my-auto" :src="modeStore.selectedMode === 'dark' ? whiteHamburgerIcon: blackHamburgerIcon" alt="display in list icon" /> -->
                            <svg v-if="isGridDisplayPattern" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 8.75H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            <path d="M3.75 15H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            <path d="M3.75 21.25H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            </svg>
                            <!-- <img v-else class="my-auto" :src="modeStore.selectedMode === 'dark' ? whiteDisplayInGridIcon : blackDisplayInGridIcon" alt="display in grid icon" /> -->
                            <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5 10.65V4.975C27.5 3.2125 26.7 2.5 24.7125 2.5H19.6625C17.675 2.5 16.875 3.2125 16.875 4.975V10.6375C16.875 12.4125 17.675 13.1125 19.6625 13.1125H24.7125C26.7 13.125 27.5 12.4125 27.5 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M27.5 24.7125V19.6625C27.5 17.675 26.7 16.875 24.7125 16.875H19.6625C17.675 16.875 16.875 17.675 16.875 19.6625V24.7125C16.875 26.7 17.675 27.5 19.6625 27.5H24.7125C26.7 27.5 27.5 26.7 27.5 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.125 10.65V4.975C13.125 3.2125 12.325 2.5 10.3375 2.5H5.2875C3.3 2.5 2.5 3.2125 2.5 4.975V10.6375C2.5 12.4125 3.3 13.1125 5.2875 13.1125H10.3375C12.325 13.125 13.125 12.4125 13.125 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.125 24.7125V19.6625C13.125 17.675 12.325 16.875 10.3375 16.875H5.2875C3.3 16.875 2.5 17.675 2.5 19.6625V24.7125C2.5 26.7 3.3 27.5 5.2875 27.5H10.3375C12.325 27.5 13.125 26.7 13.125 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="podcastCategories && podcastCategories.length > 0" class="flex justify-between justify-items-center my-auto pb-2">
                <div class="flex gap-2 overflow-x-scroll hide-scrollbar">
                    <TabList
                        v-model:activeTab="activeTab"
                        :tabs="podcastCategories"
                        @click.prevent="fetchPodcasts"
                    />
                </div>
                <!-- <div class="my-auto h-10">
                    <div class="flex gap-1 justify-between h-10 my-auto">
                        <div class="w-8 my-auto cursor-pointer">
                            <img :src="modeStore.selectedMode === 'dark' ? whiteInfoCircle : blackInfoCircle" alt="info circle icon" />
                        </div>
                        <div @click="toggleFilter()" class="w-8 my-auto cursor-pointer">
                            <img :src="modeStore.selectedMode === 'dark' ? whiteFilterIcon : blackFilterIcon" alt="search icon" />
                        </div>
                        <div class="my-auto">
                            <div @click="openCreateEditPlaylistModal()"  class="w-full py-2 px-4 rounded-md bg-gray-800 text-white cursor-pointer"><span class="text-xl">+</span> Cipta Playlist</div>
                        </div>
                    </div>
                </div> -->
            </div>

          <div v-if="isGridDisplayPattern && isLoading" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
            <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
            <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
            <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
          </div>

            <!--grid layout view -->
            <div v-if="isGridDisplayPattern" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <DocumentsCard
                        class="md:min-w-0 lg:w-full"
                        v-for="podcast in podcastLists"
                        :key="podcast.id"
                        :id="podcast.id"
                        :image="podcast.thumbnails[0]?.url"
                        :topic="podcast.title"
                        :groups="podcast.groups ? podcast.groups : []"
                        :subjects="podcast.subjects ? podcast.subjects : []"
                        :tags="podcast.tags"
                        :isAddedToWorkspace="null"
                        type="podcast"
                        :contentType="podcast.content_type"  
                    />
            </div>

            <!--rows layout view -->
            <div v-else :class="{'text-black bg-white': modeStore.selectedMode === 'light', 'bg-black text-white': modeStore.selectedMode === 'dark'}" class="bg-opacity-30 rounded-xl py-[30px]" >
                <div class="grid grid-cols-12 py-2 text-base font-semibold px-6">
                    <div class="col-span-1 invisible">checkbox</div>
                    <div class="col-span-6 text-center">{{ $t('documentPage.title')}}</div>
                    <div class="col-span-3 text-center">{{ $t('documentPage.fileType')}}</div>
                </div>

                <div v-show="isLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>

                <div v-for="podcast in podcastLists" :key="podcast.id" :class="{'hover:bg-white hover:bg-opacity-10': modeStore.selectedMode === 'dark', 'hover:bg-gray-200 hover:bg-opacity-20':modeStore.selectedMode === 'light'}" class="grid grid-cols-12 py-4 px-6">
                    <div class="col-span-1 text-center my-auto">
                        <input ref="checkbox" @click="showBarAndUpdateSelectedFile(podcast.id)" type="checkbox" :id="'checkbox-' + podcast.id" class="file-checkbox h-5 w-5" :checked="checkedItemStore.isChecked(podcast.id)"/>
                    </div>
                    <div class="col-span-6 text-center my-auto">
                        <div class="flex gap-2 cursor-pointer">
                            <div v-if="podcast && podcast.thumbnails && podcast.thumbnails.length > 0">
                              <img @click.prevent="goToDocument(podcast.id)" :src="podcast.thumbnails[0]?.url" alt="course img" class="max-h-16 rounded-md">
                            </div>
                            <div v-else class="h-16 w-16 min-h-16 max-h-16 rounded-md bg-white bg-opacity-20"></div>
                            <div class="my-auto">
                                <div @click.prevent="goToDocument(podcast.id)" class="my-auto font-semibold">{{ podcast.title }}</div>
                                <div class="flex overflow-hidden">
                                    <div v-if="podcast.groups && podcast.groups.length > 0">
                                        <div  v-for="(group, index) in podcast.groups" :key="group.id" class="flex overflow-hidden">
                                            <div class="cursor-pointer">{{ group.name }}</div><span v-if="index < podcast.groups.length - 1">, </span>
                                        </div>
                                    </div>
                                    <div v-if="podcast.subjects && podcast.subjects.length > 0">
                                        <span class="px-1"> | </span>
                                    </div>
                                    <div v-if="podcast.subjects && podcast.subjects.length > 0">
                                        <div v-for="(subject, index) in podcast.subjects" :key="subject.id" class="flex overflow-hidden">
                                            <div class="cursor-pointer">{{ subject.name }}</div><span v-if="index < podcast.subjects.length - 1">, </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="text-sm">{{ document.courseLevel }}</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 text-center my-auto">
                        <div             
                            :class="{
                                'bg-[#FEF3C7] text-[#92400E]': podcast.content_type === 1,
                                'bg-green-100 text-green-800': podcast.content_type === 2,
                                'bg-gray-100 text-gray-800': podcast.content_type === 3
                            }" class="badge badge-lg border-0 rounded-xl">
                                {{ getContentTypeLabel(podcast.content_type) }}
                            </div>
                    </div>
                </div>
            </div>
          <div v-show="totalItems > 0" class="">
            <CustomPagination
                :showingFrom="showingFrom"
                :showingTo="showingTo"
                :totalItems="totalItems"
                :totalPages="totalPages"
                :currentPage="currentPage"
                :itemsPerPage="itemsPerPage"
                @change-page="changePage"
            />
          </div>
        </section>
    </div>
</template>