import { defineStore } from 'pinia'
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';

export const useNotificationTopBarStore = defineStore('showNotificationTopBar', {
    state: () => {
      return {
        isShowNotificationTopBar: false,
        notificationMessage: 'message',
        notificationType: '', // 'addAssetBroadcastToWorkspace', 'addPlaylistToWorkspace', 'removeAssetBroadcastFromWorkspace', 'removePlaylistFromWorkspace','updateUserProfile', 'addToPlaylist', 'removeFromPlaylist', 'deletePlaylist', 'addAssetsToPlaylist'
        idUsedInAction: null,
        idsUsedInAction: [],
        playlistIdUsedInAction: null,
        timeoutId: null,
      }
    },
    actions: {
        setIsShowNotificationTopBar(value) {
            //make sure only show 1 top bar at a time
            const addToPlaylistStore = useAddToPlaylistStore();
            addToPlaylistStore.closeAddOrCreatePlaylistTopBar();

            this.isShowNotificationTopBar = value;

            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
            // Set a timeout to close the notification after 5 seconds
            if (value) {
                this.timeoutId = setTimeout(() => {
                    this.resetNotificationTopBar();
                }, 5000);
            }
        },
        setNotificationMessage(notificationMessage) {
            this.notificationMessage = notificationMessage
        },
        setNotificationType(notificationType) {
            this.notificationType = notificationType
        },
        resetNotificationTopBar() {
            this.isShowNotificationTopBar = false
            this.notificationMessage = ''
            this.notificationType = ''
            document.body.classList.remove('overflow-hidden');

            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
        },
        setIdUsedInAction(id) {
            this.idUsedInAction = id
        }
    },
  })