<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import { getStrokeColor, getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useDocumentPageFilterStore } from '../store/documentPageFilter.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { useCheckedItemStore } from '@/store/checkedItem.js';
import { useTranslation } from "i18next-vue";

import i18next from 'i18next';
import axios from 'axios';
import { useRouter } from 'vue-router'
import PageNavigator from '../components/PageNavigator.vue';
import DocumentsCard from '../components/DocumentsCard.vue'
import DocumentSkeletonCard from '../components/DocumentSkeletonCard.vue';
import CustomPagination from '../components/CustomPagination.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';
import AddAgainConfirmation from '../components/AddAgainConfirmation.vue';

const router = useRouter();
const modeStore = useModeStore();
const activePageStore = useActivePageStore();
const documentPageFilterStore = useDocumentPageFilterStore();
const checkedItemStore = useCheckedItemStore();
const { isMobile } = useIsMobile();
const { t } = useTranslation();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const isGridDisplayPattern = ref(true);
// const isFilterDisplay = ref(false);
const addToPlaylistStore = useAddToPlaylistStore();
const notificationTopBarStore = useNotificationTopBarStore();

// const filterByDocumentType = ref('all');
// const filterByUploadedBy = ref('admin');
// const filterByCourseLevel = ref('all');
// const filterBySubject = ref('all');
// const filterByDate = ref();

const documentTypes = ref(null);
const uploaders = ref(null);
const courseLevels = ref(null);
const subjects = ref(null);


const currentPage = ref(1);
const itemsPerPage = ref(12);
const totalItems = ref(0);

const documentList = ref([]);
const isLoading = ref(false);
const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: t('title.DELIMaCollection') },
];

const showingFrom = computed(() => {
    return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
    return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
});

const showBarAndUpdateSelectedFile = (id) => {
    const checkbox = document.getElementById('checkbox-' + id);

    if (checkbox && checkbox.checked) {
        checkedItemStore.addCheckedItem(id);
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(true);
        addToPlaylistStore.setSelectedAssetBroadcastIds([...addToPlaylistStore.selectedAssetBroadcastIds, id]);
    } else { //unchecked
        checkedItemStore.removeCheckedItem(id);
        addToPlaylistStore.setSelectedAssetBroadcastIds(addToPlaylistStore.selectedAssetBroadcastIds.filter(fileId => fileId !== id));
    }

    if (addToPlaylistStore.selectedAssetBroadcastIds.length === 0) {
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
    }
}

const goToDocumentPageWithSubjectFilter = (id) => {
    console.log('goToDocumentPageWithSubjectFilter', id);
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterBySubject(id);
    router.push('/document');
}

const goToDocumentPageWithCourseLvlFilter = (id) => {
    console.log('goToDocumentPageWithCourseLvlFilter', id); 
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterByCourseLevel(id);
    router.push('/document');
}

const fetchFilterData = () => {
    axios.get('api/v1/asset-broadcasts/filters')
        .then(response => {
            // Update the filter options with the received data
            documentTypes.value = response.data.assetTypes;
            uploaders.value = response.data.uploadedBy;
            courseLevels.value = response.data.courseLevel;
            subjects.value = response.data.subjects;
        })
        .catch(error => {
            console.error('Failed to fetch filter data:', error);
        });
};

const fetchDocuments = () => {
    console.log('fetching documents with filters: ', documentPageFilterStore.filterByDocumentType, documentPageFilterStore.filterByUploadedBy, documentPageFilterStore.filterByCourseLevel, documentPageFilterStore.filterBySubject);
    isLoading.value = true;
    axios.get('api/v1/asset-broadcasts', {
        params: {
            status: 1,
            // content_type: filterByDocumentType.value === 'all' ? null : filterByDocumentType.value,
            // uploader_id: filterByUploadedBy.value === 'admin' ? null : filterByUploadedBy.value,
            // user_group_id: filterByCourseLevel.value === 'all' ? null : filterByCourseLevel.value,
            // tag_id: filterBySubject.value === 'all' ? null : filterBySubject.value,
            content_type: documentPageFilterStore.filterByDocumentType === 'all' ? null : documentPageFilterStore.filterByDocumentType,
            uploader_id: documentPageFilterStore.filterByUploadedBy === 'admin' ? null : documentPageFilterStore.filterByUploadedBy,
            user_group_id: documentPageFilterStore.filterByCourseLevel === 'all' ? null : documentPageFilterStore.filterByCourseLevel,
            tag_id: documentPageFilterStore.filterBySubject === 'all' ? null : documentPageFilterStore.filterBySubject,
            per_page: itemsPerPage.value,
            order_by: 'latest',
            page: currentPage.value
        }
    }).then(response => {
        //documentList.value = response.data.data;
        documentList.value = response.data.data.map(document => ({
            ...document,
            isHovered: false
        }));

        totalItems.value = response.data.total;
    }).catch(error => {
        console.error('Failed to fetch documents:', error);
    }).finally(() => {
        isLoading.value = false;
    });
};

// const changePage = (page) => {
//     currentPage.value = page;
//     fetchDocuments();
// };
const changePage = (page) => {
    currentPage.value = page;
    fetchDocuments(); 
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

const changeDisplayPattern = () => {
    isGridDisplayPattern.value = !isGridDisplayPattern.value;
};

const toggleFilter = () => {
    documentPageFilterStore.toggleFilterDisplay();
    // isFilterDisplay.value = !isFilterDisplay.value;
};

const resetFilter = () => {
    documentPageFilterStore.resetFilter();
    // filterByDocumentType.value = 'all';
    // filterByUploadedBy.value = 'admin';
    // filterByCourseLevel.value = 'all';
    // filterBySubject.value = 'all';
    // filterByDate.value = '';
    fetchDocuments();
};

const isAddRemoveWorkspaceLoading = ref(false);

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        const response = await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });

        if (response.status === 200 || response.status === 201) { 
            console.log('Added to workspace');

            // Set up notification after successful addition
            notificationTopBarStore.setIdUsedInAction(id);
            notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.addedToWorkspace'));
            notificationTopBarStore.setNotificationType('addAssetBroadcastToWorkspace');
            notificationTopBarStore.setIsShowNotificationTopBar(true);

        } else {
            console.error('Failed to add asset broadcast to workspace:', response);
        }

        fetchDocuments();
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        notificationTopBarStore.setIdUsedInAction(id);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.removedFromWorkspace'));
        notificationTopBarStore.setNotificationType('removeAssetBroadcastFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        fetchDocuments();
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const goToDocument = (id) => {
    router.push({ name: 'DocumentDetailed', params: { id: id } });
}


const cancelAddToPlaylist = () => {
    addToPlaylistStore.setSelectedPlaylistIds([]);
    addToPlaylistStore.setShowAddToPlaylistDropdown(false);

    const radios = document.querySelectorAll("input[name='playlist-radio']");
    radios.forEach(radio => {
        radio.checked = false;
    });
}

const addAnyWayToPlaylist = () => {
    if (addToPlaylistStore.selectedPlaylistIds && addToPlaylistStore.selectedPlaylistIds.length > 0) {
        let playlistId = addToPlaylistStore.selectedPlaylistIds[0];
        axios.post(`api/v1/user-playlists/${playlistId}/asset-broadcasts`, {
            assets: addToPlaylistStore.selectedAssetBroadcastIds.map((id, index) => ({
                asset_id: id,
                order: (index + 1)
            }))
        })
        .then((response) => {
            console.log('Request completed successfully', response);
            addToPlaylistStore.isShowAddAgainConfirmation = false;
            notificationTopBarStore.playlistIdUsedInAction = addToPlaylistStore.selectedPlaylistIds[0];
            cancelAddToPlaylist();
            addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
            notificationTopBarStore.notificationType = 'addAssetsToPlaylist';
            notificationTopBarStore.notificationMessage = `Ditambah Semula ke '<strong>${response.data.user_playlist_name}</strong>'`;
            notificationTopBarStore.idsUsedInAction = addToPlaylistStore.selectedAssetBroadcastIds;
            notificationTopBarStore.setIsShowNotificationTopBar(true);
            checkedItemStore.clearCheckedItems();
        })
        .catch((error) => {
            console.error('Error in the request', error);
        })
        .finally(() => {
            addToPlaylistStore.setIsAddingToPlaylistLoading(false);
        });
    } else {
        console.log('No playlist selected');
        addToPlaylistStore.isShowAddAgainConfirmation = false;
        cancelAddToPlaylist();
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
    }
}

onMounted(() => {
    console.log('Document page mounted, filter');
    console.log('documentPageFilterStore.filterByDocumentType', documentPageFilterStore.filterByDocumentType);
    console.log('documentPageFilterStore.filterByUploadedBy', documentPageFilterStore.filterByUploadedBy);
    console.log('documentPageFilterStore.filterByCourseLevel', documentPageFilterStore.filterByCourseLevel);
    console.log('documentPageFilterStore.filterBySubject', documentPageFilterStore.filterBySubject);
    addToPlaylistStore.closeAddOrCreatePlaylistTopBar();
    activePageStore.setSelectedActivePage(5);
    fetchFilterData();
    fetchDocuments();
    //fetchFilterData();
});

onUnmounted(() => {
    documentPageFilterStore.resetFilter();
    checkedItemStore.clearCheckedItems();
});

// watch([filterByDocumentType, filterByUploadedBy, filterByCourseLevel, filterBySubject, filterByDate], () => {
//     currentPage.value = 1;
//     fetchDocuments();
// });
// watch([documentPageFilterStore.filterByDocumentType, documentPageFilterStore.filterByUploadedBy, documentPageFilterStore.filterByCourseLevel, documentPageFilterStore.filterBySubject], () => {
//   currentPage.value = 1;
//   console.log('watch filter');
//   fetchDocuments();
// });
watch(
  () => [
    documentPageFilterStore.filterByDocumentType,
    documentPageFilterStore.filterByUploadedBy,
    documentPageFilterStore.filterByCourseLevel,
    documentPageFilterStore.filterBySubject,
  ],
  (newValues) => {
    console.log('Filters changed:', newValues);
    console.log('documentPageFilterStore.filterBySubject', documentPageFilterStore.filterBySubject);
    currentPage.value = 1;
    fetchDocuments();
  }
);

</script>
<template>
    <!--Fail-->
    <div class="min-h-screen overflow-x-scroll hide-scrollbar">
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <AddOrCreatePlaylistTopBar v-show="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-show="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="false"/>
        <AddAgainConfirmation v-if="addToPlaylistStore.isShowAddAgainConfirmation" @addanyway-to-playlist="addAnyWayToPlaylist" :title="addToPlaylistStore.selectedPlaylistName"/>
        <!-- <page-navigator from="Laman Utama" :to="$t('breadcrumb.mainCollections')" /> -->
        <page-navigator :breadcrumbs="breadcrumbs" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ $t('title.DELIMaCollection')}}</h2>
        </div>
        <section 
            class="rounded-xl p-[30px] bg-transparent"
            :class="{'bg-transparent text-black': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >
            <div class="flex justify-between justify-items-center my-auto">
                <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.file')}}</h2>
                <div class="my-auto h-10">
                    <div class="flex gap-1 justify-between h-10 my-auto">
                        <div @click="changeDisplayPattern()" class=" w-8 my-auto cursor-pointer">
                            <!-- <img v-if="isGridDisplayPattern" class="my-auto" :src="modeStore.selectedMode === 'dark' ? whiteHamburgerIcon: blackHamburgerIcon" alt="display in list icon" /> -->
                            <svg v-if="isGridDisplayPattern" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 8.75H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            <path d="M3.75 15H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            <path d="M3.75 21.25H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            </svg>
                            <!-- <img v-else class="my-auto" :src="modeStore.selectedMode === 'dark' ? whiteDisplayInGridIcon : blackDisplayInGridIcon" alt="display in grid icon" /> -->
                            <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5 10.65V4.975C27.5 3.2125 26.7 2.5 24.7125 2.5H19.6625C17.675 2.5 16.875 3.2125 16.875 4.975V10.6375C16.875 12.4125 17.675 13.1125 19.6625 13.1125H24.7125C26.7 13.125 27.5 12.4125 27.5 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M27.5 24.7125V19.6625C27.5 17.675 26.7 16.875 24.7125 16.875H19.6625C17.675 16.875 16.875 17.675 16.875 19.6625V24.7125C16.875 26.7 17.675 27.5 19.6625 27.5H24.7125C26.7 27.5 27.5 26.7 27.5 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.125 10.65V4.975C13.125 3.2125 12.325 2.5 10.3375 2.5H5.2875C3.3 2.5 2.5 3.2125 2.5 4.975V10.6375C2.5 12.4125 3.3 13.1125 5.2875 13.1125H10.3375C12.325 13.125 13.125 12.4125 13.125 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.125 24.7125V19.6625C13.125 17.675 12.325 16.875 10.3375 16.875H5.2875C3.3 16.875 2.5 17.675 2.5 19.6625V24.7125C2.5 26.7 3.3 27.5 5.2875 27.5H10.3375C12.325 27.5 13.125 26.7 13.125 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="hidden w-8 my-auto cursor-pointer">
                            <!-- <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15 10V16.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.9922 20H15.0034" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> -->
                            <!-- <img :src="modeStore.selectedMode === 'dark' ? whiteInfoCircle : blackInfoCircle" alt="info circle icon" /> -->
                        </div>
                        <div @click="toggleFilter()" class="w-8 my-auto cursor-pointer">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.75 2.62402H23.25C24.625 2.62402 25.75 3.74902 25.75 5.12402V7.87402C25.75 8.87402 25.125 10.124 24.5 10.749L19.125 15.499C18.375 16.124 17.875 17.374 17.875 18.374V23.749C17.875 24.499 17.375 25.499 16.75 25.874L15 26.999C13.375 27.999 11.125 26.874 11.125 24.874V18.249C11.125 17.374 10.625 16.249 10.125 15.624L5.375 10.624C4.75 9.99902 4.25 8.87402 4.25 8.12402V5.24902C4.25 3.74902 5.375 2.62402 6.75 2.62402Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <!-- <img :src="modeStore.selectedMode === 'dark' ? whiteFilterIcon : blackFilterIcon" alt="search icon" /> -->
                        </div>
                    </div>
                </div>
            </div>

            <!--Filters-->
            <div v-if="documentPageFilterStore.isFilterDisplay">
                <div class="flex justify-between">
                    <h3 :class="modeStore.selectedMode === 'dark'? 'text-white' : 'text-black'" class="text-xl">{{ $t('documentPage.filter')}}</h3>
                    <div @click="resetFilter()" class="cursor-pointer">
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9173 13.0007C22.9173 18.7507 18.2507 23.4173 12.5007 23.4173C6.75065 23.4173 3.24023 17.6257 3.24023 17.6257M3.24023 17.6257H7.94857M3.24023 17.6257V22.834M2.08398 13.0007C2.08398 7.25065 6.70898 2.58398 12.5007 2.58398C19.4486 2.58398 22.9173 8.37565 22.9173 8.37565M22.9173 8.37565V3.16732M22.9173 8.37565H18.2923" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <!-- <div @click="resetFilter()" class="text-red-400 cursor-pointer">{{ $t('documentPage.resetFilter')}}</div> -->
                </div>
            
                <div class="grid md:grid-cols-3 gap-10 py-4">
                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <!-- <p>{{ $t('documentPage.fileType')}}</p> -->
                        <select v-model="documentPageFilterStore.filterByDocumentType" 
                            
                            class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white hover:cursor-pointer">
                            <option value="all" class="text-black">{{ $t('documentPage.fileType')}}</option>
                            <!-- <option value="all" class="text-black">{{ $t('documentPage.all')}}</option> -->
                            <option v-for="type in documentTypes" :value="type" :key="type" class="text-black">{{ type }}</option>
                        </select>
                    </div>
                    <!-- <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <select v-model="documentPageFilterStore.filterByUploadedBy" class="w-full py-2 px-4 my-2 rounded-md border-[1px]  border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                            <option value='admin' class="text-black">{{ $t('documentPage.uploadedBy')}}</option>
                            <option v-for="uploader in uploaders" :value="uploader.id" :key="uploader.id" class="text-black">{{ uploader.name }}</option>
                        </select>
                    </div> -->
                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <!-- <p>{{ $t('documentPage.grade')}}</p> -->
                        <select v-model="documentPageFilterStore.filterByCourseLevel" class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                            <option value="all" class="text-black">{{ $t('documentPage.grade')}}</option>
                            <!-- <option value="all" class="text-black">Pilih satu pilihan</option> -->
                            <option v-for="level in courseLevels" :value="level.id" :key="level.id" class="text-black">{{ level.name }}</option>
                        </select>
                    </div>
                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <!-- <p>{{ $t('documentPage.subject')}}</p> -->
                        <select v-model="documentPageFilterStore.filterBySubject" class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                            <option value="all" class="text-black">{{ $t('documentPage.subject')}}</option>
                            <!-- <option value="all" class="text-black">Semua</option> -->
                            <option v-for="subject in subjects" :value="subject.id" :key="subject.id" class="text-black">{{ subject.name }}</option>
                        </select>
                    </div>
                    <!-- <div>
                        <p>{{ $t('documentPage.uploadDate')}}</p>
                        <VueDatePicker v-model="documentPageFilterStore.filterByDate" :enable-time-picker="false"  :dark="modeStore.selectedMode === 'dark'"
                         class="w-[70%] py-2 rounded-md" />
                    </div> -->
                </div>
                
            </div>

            <!-- <div v-show="isGridDisplayPattern && isLoading" class="flex items-center justify-center w-full gap-2">
                <div>{{ $t('general.loading')}}</div>
                <span class="loading loading-bars loading-sm"></span>
            </div> -->
            <div v-if="isGridDisplayPattern && isLoading" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
            </div>
            

            <!--grid layout view -->
            <div v-if="isGridDisplayPattern && !isLoading" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <DocumentsCard
                        class="md:min-w-0 lg:w-full"
                        v-for="asset in documentList"
                        :key="asset.id"
                        :id="asset.id"
                        :image="asset.thumbnails[0]?.url"
                        :topic="asset.title"
                        :groups="asset.groups ? asset.groups : []"
                        :subjects="asset.subjects ? asset.subjects : []"
                        :postedTime="asset.created_at_formatted"
                        :tags="asset.tags"
                        :isAddedToWorkspace="asset.has_added_to_workspace"
                        :contentType="asset.content_type"
                        @refresh-asset-api="fetchDocuments"
                    />
            </div>

            <!--rows layout view -->
            <div v-if="!isGridDisplayPattern && !isLoading" :class="{'text-black bg-white': modeStore.selectedMode === 'light', 'bg-black text-white': modeStore.selectedMode === 'dark'}" class="min-w-[600px] bg-opacity-30 rounded-xl" >
                <div class="grid grid-cols-12 py-4 text-base font-semibold px-6 rounded-t-xl" :class="{'bg-white': modeStore.selectedMode === 'light'}">
                    <div class="col-span-1 invisible">checkbox</div>
                    <div class="col-span-6 text-center">{{ $t('documentPage.title')}}</div>
                    <div class="col-span-3 text-center">{{ $t('documentPage.fileType')}}</div>
                    <!-- <div class="col-span-2 text-center">{{ $t('documentPage.dateAdded')}}</div> -->
                    <div class="col-span-2 text-center">{{ $t('documentPage.action')}}</div>
                </div>
                <div v-show="isLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>
                <div v-for="document in documentList" :key="document.id" :class="{'hover:bg-white hover:bg-opacity-10': modeStore.selectedMode === 'dark', 'bg-white hover:bg-gray-200 hover:bg-opacity-20':modeStore.selectedMode === 'light'}" class="grid grid-cols-12 py-4 px-6">
                    <div class="col-span-1 text-center my-auto">
                        <input ref="checkbox" @click="showBarAndUpdateSelectedFile(document.id)" type="checkbox" :id="'checkbox-' + document.id" class="file-checkbox h-5 w-5" :checked="checkedItemStore.isChecked(document.id)"/>
                    </div>
                    <div class="col-span-6 text-center my-auto">
                        <div class="flex gap-2 cursor-pointer">
                            <div v-if="document && document.thumbnails && document.thumbnails.length > 0">
                                <img @click.prevent="goToDocument(document.id)" :src="document.thumbnails[0]?.url" alt="course img" class="max-h-16 rounded-md">
                            </div>
                            <div v-else class="h-16 w-16 min-h-16 max-h-16 rounded-md bg-white bg-opacity-20"></div>
                            <div class="my-auto">
                                <div @click.prevent="goToDocument(document.id)" class="my-auto font-semibold">{{ document.title }}</div>
                                <div class="flex overflow-hidden">
                                    <div v-if="document.groups && document.groups.length > 0">
                                        <div  v-for="(group, index) in document.groups" :key="group.id" class="flex overflow-hidden">
                                            <div class="cursor-pointer" @click="goToDocumentPageWithCourseLvlFilter(group.id)">{{ group.name }}</div><span v-if="index < document.groups.length - 1">, </span>
                                        </div>
                                    </div>
                                    <div v-if="document.subjects && document.subjects.length > 0">
                                        <span class="px-1"> | </span>
                                    </div>
                                    <div v-if="document.subjects && document.subjects.length > 0">
                                        <div v-for="(subject, index) in document.subjects" :key="subject.id" class="flex overflow-hidden">
                                            <div class="cursor-pointer" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < document.subjects.length - 1">, </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="text-sm">{{ document.courseLevel }}</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 text-center my-auto">
                        <div             
                            :class="{
                                'bg-[#FEF3C7] text-[#92400E]': document.content_type === 1,
                                'bg-green-100 text-green-800': document.content_type === 2,
                                'bg-gray-100 text-gray-800': document.content_type === 3
                            }" class="badge badge-lg border-0 rounded-xl">
                                {{ getContentTypeLabel(document.content_type) }}
                            </div>
                        <!-- <div v-if="document.content_type === 1">{{ $t('general.video')}}</div>
                        <div v-if="document.content_type === 2">{{ $t('general.interactive')}}</div>
                        <div v-if="document.content_type === 3">{{ $t('general.document')}}</div> -->
                    </div>
                    <!-- <div class="col-span-2 text-center my-auto">{{ document.created_at_formatted }}</div> -->
                    <div class="col-span-2 text-center my-auto mx-auto">
                        <div class="flex gap-2">
                            <div v-if="document.has_added_to_workspace" @click="removeFromWorkspace(document.id)"  class="cursor-pointer">
                                <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                    <path d="M17.6257 3.73047C15.7402 3.73047 14.0527 4.64714 13.0007 6.05339C11.9486 4.64714 10.2611 3.73047 8.37565 3.73047C5.17773 3.73047 2.58398 6.33464 2.58398 9.55338C2.58398 10.793 2.7819 11.9388 3.12565 13.0013C4.77148 18.2096 9.8444 21.3242 12.3548 22.1784C12.709 22.3034 13.2923 22.3034 13.6465 22.1784C16.1569 21.3242 21.2298 18.2096 22.8757 13.0013C23.2194 11.9388 23.4173 10.793 23.4173 9.55338C23.4173 6.33464 20.8236 3.73047 17.6257 3.73047Z" :fill="strokeColor"/>
                                </svg>
                            </div>
                            <div v-else @click="addToWorkspace(document.id)" class="cursor-pointer text-black" @mouseover="document.isHovered = true" @mouseout="document.isHovered = false">
                                <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M13.1465 21.6798C12.7923 21.8048 12.209 21.8048 11.8548 21.6798C8.83398 20.6486 2.08398 16.3465 2.08398 9.05485C2.08398 5.8361 4.67773 3.23193 7.87565 3.23193C9.77148 3.23193 11.4486 4.1486 12.5006 5.56527C13.5527 4.1486 15.2402 3.23193 17.1256 3.23193C20.3236 3.23193 22.9173 5.8361 22.9173 9.05485C22.9173 16.3465 16.1673 20.6486 13.1465 21.6798Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pagination -->
            <div class="">
                <CustomPagination
                    :showingFrom="showingFrom"
                    :showingTo="showingTo"
                    :totalItems="totalItems"
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :itemsPerPage="itemsPerPage"
                    @change-page="changePage"
                />
            </div>
        </section>
    </div>
</template>

<style scoped>


</style>