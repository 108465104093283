<script setup>
import { ref, defineEmits, defineProps } from 'vue';
// import axios from 'axios';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';

const modeStore = useModeStore();
const themeStore = useThemeStore();
const addToPlaylistStore = useAddToPlaylistStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();

const emit = defineEmits(['addanyway-to-playlist']);

const isLoading = ref(false);
const confirmAddToPlaylist = () => {
    console.log('teruskan tambah')
    emit('addanyway-to-playlist');
}

const props = defineProps({
    title: String
});

</script>

<template>
    <div>
        <div class="fixed inset-0 flex items-center justify-center" style="z-index:999;">
            <div class="fixed inset-0 bg-black opacity-80"></div>
            <div class="relative p-8 rounded-lg shadow-lg md:w-1/2" :class="{'bg-white': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}">
            <div class="flex justify-end">
                <svg @click="addToPlaylistStore.isShowAddAgainConfirmation = false" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.8749 21.8749L3.125 3.125M21.8751 3.125L3.125 21.8751" :stroke="modeStrokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <!-- <div class="flex flex-col items-center justify-center mx-auto pb-4">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.875 6.22786C18.4062 5.88411 14.9167 5.70703 11.4375 5.70703C9.375 5.70703 7.3125 5.8112 5.25 6.01953L3.125 6.22786" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.85352 5.17578L9.08268 3.8112C9.24935 2.82161 9.37435 2.08203 11.1348 2.08203H13.8639C15.6243 2.08203 15.7598 2.86328 15.916 3.82161L16.1452 5.17578" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.6361 9.51953L18.959 20.0091C18.8444 21.6445 18.7506 22.9154 15.8444 22.9154H9.1569C6.25065 22.9154 6.1569 21.6445 6.04232 20.0091L5.36523 9.51953" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.7598 17.1875H14.2285" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.89648 13.0195H15.1048" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div> -->

            <div :class="{'bg-white text-black': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}" class="text-center">
                <div class="text-lg font-semibold">Sudah Ditambah</div>
                <div class="">Fail sudah ditambah ke dalam {{ props.title }}.</div>
            </div>


            <div class="flex gap-2 justify-center my-auto pt-4">
                <button :disabled="isLoading" @click="addToPlaylistStore.isShowAddAgainConfirmation = false" :class="[modeStore.selectedMode === 'dark'? 'border-gray-600 bg-white text-black': 'text-black', isLoading? 'opacity-50': 'cursor-pointer']"  class="text-sm py-2 px-4 rounded-lg border">{{ $t('general.cancel')}}</button>
                <div class="max-h-6 max-w-6 my-auto" :class="isLoading? 'spinner': ''" :style="{borderLeftColor: strokeColor}"></div>
                <button :disabled="isLoading" @click="confirmAddToPlaylist()" :data-theme='themeStore.selectedTheme' :class="isLoading? 'opacity-50': 'cursor-pointer'" class="text-sm py-2 px-4 rounded-lg text-white ">{{ $t('general.addAnyway')}}</button>
            </div>
            </div>
        </div>
    </div>
</template>