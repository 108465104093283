<script setup>
// eslint-disable-next-line
const props = defineProps({
    id: Number,
    image: String,
    topic: String,
    groups: Array,
    subjects: Array,
    postedTime: String,
    tags: Array,
    isAddedToWorkspace: Boolean,
    contentType: Number,
    type: String,
});

import i18next from 'i18next';

import axios from 'axios';
import { computed, ref, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import useIsMobile from '../composables/isMobile.js';
import { getStrokeColor, getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useDocumentPageFilterStore } from '../store/documentPageFilter.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { useCheckedItemStore } from '@/store/checkedItem.js';

import playIcon from '../assets/icons/play_icon.png';

const checkedItemStore = useCheckedItemStore();
const addToPlaylistStore = useAddToPlaylistStore();
const documentPageFilterStore = useDocumentPageFilterStore();
const notificationTopBarStore = useNotificationTopBarStore();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const { isMobile } = useIsMobile();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const isHovered = ref(false);
const emit = defineEmits(['refresh-asset-api']);
const router = useRouter()

const displayTopic = computed(() => {
    return isMobile.value ? `${props.topic.slice(0, 25)}...` : props.topic;
});


const isAddRemoveWorkspaceLoading = ref(false);

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        const response = await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });

        // Check if the response indicates success
        if (response.status === 200 || response.status === 201) { 
            console.log('Added to workspace');

            // Set up notification after successful addition
            notificationTopBarStore.setIdUsedInAction(id);
            notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.addedToWorkspace'));
            notificationTopBarStore.setNotificationType('addAssetBroadcastToWorkspace');
            notificationTopBarStore.setIsShowNotificationTopBar(true);

            // Emit an event to refresh the asset API
            emit('refresh-asset-api');
        } else {
            console.error('Failed to add asset broadcast to workspace:', response);
        }
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        notificationTopBarStore.setIdUsedInAction(id);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.removedFromWorkspace'));
        notificationTopBarStore.setNotificationType('removeAssetBroadcastFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-asset-api');
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const goToDocument = (id) => {
    if(props.type === 'podcast'){
        router.push({ name: 'Podcast Details', params: { id: id } });
    }
    else{
        router.push({ name: 'DocumentDetailed', params: { id: id } });
    }
}

const showBarAndUpdateSelectedFile = (id) => {
    const checkbox = document.getElementById('checkbox-' + id);

    if (checkbox && checkbox.checked) {
        checkedItemStore.addCheckedItem(id);
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(true);
        addToPlaylistStore.setSelectedAssetBroadcastIds([...addToPlaylistStore.selectedAssetBroadcastIds, id]); //selectedAssetBroadcastIds will only have 1 id at a time due to changes in flow, disabled multi-select
    } else { //unchecked
        checkedItemStore.removeCheckedItem(id);
        addToPlaylistStore.setSelectedAssetBroadcastIds(addToPlaylistStore.selectedAssetBroadcastIds.filter(fileId => fileId !== id));
    }

    if (addToPlaylistStore.selectedAssetBroadcastIds.length === 0) {
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
    }
}

const goToDocumentPageWithSubjectFilter = (id) => {
    console.log('goToDocumentPageWithSubjectFilter', id); //id here is 202, but in filter it is 133
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterBySubject(id);
    router.push('/document');
}

const goToDocumentPageWithCourseLvlFilter = (id) => {
    console.log('goToDocumentPageWithCourseLvlFilter', id); 
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterByCourseLevel(id);
    router.push('/document');
}
</script>

<template>
    <div 
        class="card group card-compact rounded-xl p-2 bg-transparent transition-all ease-in-out duration-75"
        :class="{'hover:bg-white hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        <figure 
            @click.prevent="goToDocument(id)" 
            class="w-full aspect-square relative rounded-xl cursor-pointer overflow-hidden"
        >
            <div v-if="!image" class="w-full h-full"></div>
            <div v-else class="relative w-full h-full flex items-center justify-center">
                <img 
                    :src="image" 
                    alt="Document Image" 
                    class="w-full h-full  object-contain"
                />
                <img 
                    v-if="playIcon"
                    :src="playIcon" 
                    alt="Play Icon"
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/5 h-1/5 cursor-pointer"
                />
            </div>
        </figure>
        <div :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink'  }" 
            class="absolute right-5 top-5 cursor-pointer  block md:block">
            <input ref="checkbox" @click="showBarAndUpdateSelectedFile(props.id)" type="checkbox" :id="'checkbox-' + props.id" class="file-checkbox h-5 w-5" :checked="checkedItemStore.isChecked(props.id)"/>
            <!-- <AddToPlaylistDropdown :currentFileId="props.id"/> -->
        </div>
        <div class="flex flex-row overflow-hidden">
            <div class="card-body basis-1/5 2xl:basis-4/5 mt-2" style="padding: 0; gap: 0;">
                <a href="#" @click.prevent="goToDocument(id)" class="card-title text-sm md:text-xl cursor-pointer min-h-[30px] md:min-h-[60px] line-clamp-2">{{ displayTopic }}</a>
                <!-- <p class="pb-1 card-description">{{ postedTime }}</p> -->
                <div v-if="(groups && groups.length > 0) || (subjects && subjects.length > 0)" class="md:flex pb-3 overflow-hidden text-sm md:text-base">
                    <div v-if="groups && groups.length > 0">
                        <div  v-for="(group, index) in groups" :key="group.id" class="flex overflow-hidden">
                            <div class="cursor-pointer max-w-[90px] truncate" @click="goToDocumentPageWithCourseLvlFilter(group.id)">{{ group.name }}</div><span v-if="index < groups.length - 1">, </span>
                        </div>
                    </div>
                    <div v-if="subjects && subjects.length > 0">
                        <span class="px-1 hidden md:block"> | </span>
                    </div>
                    <div v-if="subjects && subjects.length > 0">
                        <div v-for="(subject, index) in subjects" :key="subject.id" class="flex overflow-hidden w-full">
                            <div class="cursor-pointer line-clamp-1" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < subjects.length - 1">, </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="props.isAddedToWorkspace != null" id="step-7" class="flex flex-row justify-end items-start mt-[0.3rem]">
                <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(id)"  class="cursor-pointer">
                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                    <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path d="M17.6257 3.73047C15.7402 3.73047 14.0527 4.64714 13.0007 6.05339C11.9486 4.64714 10.2611 3.73047 8.37565 3.73047C5.17773 3.73047 2.58398 6.33464 2.58398 9.55338C2.58398 10.793 2.7819 11.9388 3.12565 13.0013C4.77148 18.2096 9.8444 21.3242 12.3548 22.1784C12.709 22.3034 13.2923 22.3034 13.6465 22.1784C16.1569 21.3242 21.2298 18.2096 22.8757 13.0013C23.2194 11.9388 23.4173 10.793 23.4173 9.55338C23.4173 6.33464 20.8236 3.73047 17.6257 3.73047Z" :fill="strokeColor"/>
                    </svg>
                </div>
                <div v-else @click="addToWorkspace(id)" class="cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                    <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M13.1465 21.6798C12.7923 21.8048 12.209 21.8048 11.8548 21.6798C8.83398 20.6486 2.08398 16.3465 2.08398 9.05485C2.08398 5.8361 4.67773 3.23193 7.87565 3.23193C9.77148 3.23193 11.4486 4.1486 12.5006 5.56527C13.5527 4.1486 15.2402 3.23193 17.1256 3.23193C20.3236 3.23193 22.9173 5.8361 22.9173 9.05485C22.9173 16.3465 16.1673 20.6486 13.1465 21.6798Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap gap-2">
            <!-- <div v-for="tag in tags" :key="tag" class="badge badge-lg border-0 rounded-xl bg-[#FEF3C7] text-[#92400E] h-auto">{{ tag.name }}</div> -->
            <div v-if="contentType" class="badge badge-lg border-0 rounded-xl h-auto"
            :class="{
            'bg-[#FEF3C7] text-[#92400E]': contentType === 1,
            'bg-green-100 text-green-800': contentType === 2,
            'bg-purple-100 text-purple-800': contentType === 3
        }"
            >
            {{ getContentTypeLabel(contentType) }}
            </div>
        </div>
    </div>
</template>