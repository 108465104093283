<script setup>
// eslint-disable-next-line
const props = defineProps({
    playlistId: Number,
    image: String,
    subject: String,
    teacher: String,
    grade: Array,
    documentCount: Number,
    interactionCount: Number,
    videoCount: Number,
    isAddedToWorkspace: Boolean,
    isTeacherPlaylist: Boolean,
});

import i18next from 'i18next';

import axios from 'axios';
import { ref, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useWorkspaceStore } from '../store/workspace.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

const modeStore = useModeStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const workspaceStore = useWorkspaceStore();
const isHovered = ref(false);
const emit = defineEmits(['refresh-playlist-api']);
const router = useRouter();
const isAddRemoveWorkspaceLoading = ref(false);
const notificationTopBarStore = useNotificationTopBarStore();

const addToWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.post('api/v1/workspaces/playlists', {
            playlist_id: playlistId
        });
        workspaceStore.setAction('add');
        workspaceStore.setPlaylistId(playlistId);

        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.addedToWorkspace'));
        notificationTopBarStore.setNotificationType('addPlaylistToWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-playlist-api');
    } catch (error) {
        console.error('Error adding playlist to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/playlists/${playlistId}`);
        workspaceStore.setAction('remove');
        workspaceStore.setPlaylistId(playlistId);

        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.removedFromWorkspace'));
        notificationTopBarStore.setNotificationType('removePlaylistFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-playlist-api');
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

// const gradeString = computed(() => props.grade.join(' | '));

const goToPlaylistPlay = () => {
    router.push({
        name: 'Play Playlist',
        params: { id: props.playlistId },
        query: { isTeacherPlaylist: props.isTeacherPlaylist }
    });
}
</script>

<template>
    <div 
    class="card card-compact rounded-[30px] p-[10px] group transition-all ease-in-out duration-75"
    :class="{'hover:bg-white hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        <figure class="w-full rounded-[30px] h-36 xl:h-48 2xl:h-72 cursor-pointer" @click="goToPlaylistPlay">
            <div v-if="!image" class="bg-white bg-opacity-20 min-w-full min-h-full"></div>
            <div v-else>
                <img class="w-full h-full object-cover bg-gray-400" 
                :src="image" alt="Playlist Card Image" />
            </div>
        </figure>
        <div class="card-body mt-2" style="padding: 0; gap: 0;">
            <div class="flex justify-between items-start">
                <div class="">
                    <div class="flex flex-row items-start justify-start w-full">
                        <h2 class="text-lg leading-7 font-semibold cursor-pointer line-clamp-2 max-w-2/3 min-h-[60px]" @click="goToPlaylistPlay">{{ subject }}</h2>
                    </div>
                    <!-- <div class="pb-1 card-description">{{ gradeString }}</div> -->
                </div>
                <div class="flex items-start justify-end">
                    <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(playlistId)"  class="my-auto cursor-pointer">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path d="M17.6257 3.73047C15.7402 3.73047 14.0527 4.64714 13.0007 6.05339C11.9486 4.64714 10.2611 3.73047 8.37565 3.73047C5.17773 3.73047 2.58398 6.33464 2.58398 9.55338C2.58398 10.793 2.7819 11.9388 3.12565 13.0013C4.77148 18.2096 9.8444 21.3242 12.3548 22.1784C12.709 22.3034 13.2923 22.3034 13.6465 22.1784C16.1569 21.3242 21.2298 18.2096 22.8757 13.0013C23.2194 11.9388 23.4173 10.793 23.4173 9.55338C23.4173 6.33464 20.8236 3.73047 17.6257 3.73047Z" :fill="strokeColor"/>
                        </svg>
                    </div>
                    <div v-else @click="addToWorkspace(playlistId)" class="my-auto cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path d="M13.1465 21.6798C12.7923 21.8048 12.209 21.8048 11.8548 21.6798C8.83398 20.6486 2.08398 16.3465 2.08398 9.05485C2.08398 5.8361 4.67773 3.23193 7.87565 3.23193C9.77148 3.23193 11.4486 4.1486 12.5006 5.56527C13.5527 4.1486 15.2402 3.23193 17.1256 3.23193C20.3236 3.23193 22.9173 5.8361 22.9173 9.05485C22.9173 16.3465 16.1673 20.6486 13.1465 21.6798Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div 
                class="rounded-[30px] grid grid-cols-3 gap-2 py-2 px-2"
                :class="{'group-hover:bg-custom-light-gray': modeStore.selectedMode === 'light', 'group-hover:bg-custom-blue-gray': modeStore.selectedMode === 'dark'}"
            >
                <div class="playlist-card-description flex items-center justify-center">{{ documentCount }} {{ $t('general.document') }}</div>
                <div class="playlist-card-description flex items-center justify-center border-x-2">{{ interactionCount }} {{ $t('general.interactive') }}</div>
                <div class="playlist-card-description flex items-center justify-center">{{ videoCount }} {{ $t('general.video') }}</div>
            </div>
        </div>
    </div>
</template>