<script setup>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue'
import { useModeStore } from '../store/mode.js';
import { useTranslation } from "i18next-vue";
import useIsMobile from '../composables/isMobile.js';

import PageNavigator from '../components/PageNavigator.vue';
import TeachersCard from '../components/TeachersCard.vue';
import CustomPagination from '../components/CustomPagination.vue';

// Import images
import FilterBlack from '../assets/icons/black_filter_icon.svg';
import FilterWhite from '../assets/icons/white_filter_icon.svg';

const modeStore = useModeStore();
const { isMobile } = useIsMobile();
const { t } = useTranslation();
const isLoadingCikgu = ref(false);
const currentPage = ref(0);
const itemsPerPage = ref(35); // Default itemsPerPage to 35
const totalItems = ref(0);
const teacherListData = ref([]);
const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: t('title.playlist'), to: { name: 'Playlist Page' } },
  { text: t('title.teacherList') },
];

onMounted(() => {
    // get api/v1/users?role=cikgu
    isLoadingCikgu.value = true;
    axios.get('api/v1/users?role=cikgu&per_page=35') // Default itemsPerPage to 35
        .then((response) => {
            currentPage.value = response.data.current_page;
            showingTo.value = response.data.to;
            totalItems.value = response.data.total;
            teacherListData.value = response.data.data;
        })
        .catch((error) => {
            console.error('Failed to fetch teacher list data:', error);
        })
        .finally(() => {
            isLoadingCikgu.value = false;
        });
});

const showingFrom = computed(() => {
    return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
    // If this is the last page, return the total items, else return the current page * items per page
    return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
});

const changePage = (page) => {
    currentPage.value = page;
    // get api/v1/users?role=cikgu&page=newPage
    isLoadingCikgu.value = true;
    axios.get(`api/v1/users?role=cikgu&page=${page}`)
        .then((response) => {
            currentPage.value = response.data.current_page;
            showingTo.value = response.data.to;
            totalItems.value = response.data.total;
            teacherListData.value = response.data.data;
        })
        .catch((error) => {
            console.error('Failed to fetch teacher list data:', error);
        })
        .finally(() => {
            isLoadingCikgu.value = false;
        });

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};
</script>

<template>
    <div class="min-h-screen">
        <page-navigator :breadcrumbs="breadcrumbs" />
        <!-- <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto p-4">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">Cikgu</h2>
        </div> -->
        <section>
            <div 
                class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
                :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
            >
                <div 
                    class="flex items-center justify-between mb-4"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Senarai Cikgu</h2>
                    <div class="flex flex-row gap-6">
                        <!-- <img :src="modeStore.selectedMode === 'light' ? MenuBlack : MenuWhite" alt="Menu Icon" /> -->
                        <img :src="modeStore.selectedMode === 'light' ? FilterBlack : FilterWhite" alt="Filter Icon" />
                    </div>
                </div>

                <div v-show="isLoadingCikgu" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>

                <div
                    v-show="!isLoadingCikgu"
                    class="grid grid-cols-2 md:grid-cols-7 md:gap-y-10 justify-items-center"
                    :class="{'avatar-mobile-container' : isMobile}"
                >
                    <TeachersCard
                        class="md:min-w-0 lg:w-[80%]"
                        v-for="teacher in teacherListData"
                        :key="teacher.id"
                        :image="teacher.avatar_url"
                        :name="teacher.name"
                        :role="teacher.role_name"
                        :id="teacher.id"
                    />
                </div>

                <div class="hidden lg:block" v-show="!isLoadingCikgu">
                    <CustomPagination
                        :showingFrom="showingFrom"
                        :showingTo="showingTo"
                        :totalItems="totalItems"
                        :totalPages="totalPages"
                        :currentPage="currentPage"
                        :itemsPerPage="itemsPerPage"
                        @change-page="changePage"
                    />
                </div>
            </div>
        </section>
    </div>
</template>