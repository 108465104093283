import { defineStore } from 'pinia';
import { useNotificationTopBarStore } from './notificationTopBar';

export const useAddToPlaylistStore = defineStore('addToPlaylist', {
    state: () => {
      return {
        showAddOrCreatePlaylistTopBar: false,
        showAddToPlaylistDropdown: false,
        showCreateOrEditPlaylistModal: false,
        selectedAssetBroadcastIds: [],
        selectedPlaylistIds: [],
        selectedPlaylistName: null,
        modalMode: 'create', //create/edit
        redirectToCreatePlaylistPage: true,
        userPlaylists: [],
        isAddingToPlaylistLoading: false,
        isShowAddAgainConfirmation: false,
      }
    },
    actions: {
        setShowAddOrCreatePlaylistTopBar(value) {
            //make sure only show 1 top bar at a time
            const notificationTopBarStore = useNotificationTopBarStore();
            notificationTopBarStore.resetNotificationTopBar();

            this.showAddOrCreatePlaylistTopBar = value
        },
        setShowAddToPlaylistDropdown(value) {
            this.showAddToPlaylistDropdown = value
        },
        setShowCreateOrEditPlaylistModal(value) {
            this.showCreateOrEditPlaylistModal = value
        },
        setSelectedAssetBroadcastIds(assetBroadcastIds) {
            this.selectedAssetBroadcastIds = assetBroadcastIds
        },
        setSelectedPlaylistIds(playlistIds) {
            this.selectedPlaylistIds = playlistIds
        },
        closeAddOrCreatePlaylistTopBar() {
            this.showAddOrCreatePlaylistTopBar = false
            this.showAddToPlaylistDropdown = false
            this.selectedAssetBroadcastIds = []
            this.selectedPlaylistIds = []
        },
        setModalMode(mode) {
            this.modalMode = mode
        },
        setRedirectToCreatePlaylistPage(value) {
            this.redirectToCreatePlaylistPage = value
        },
        setUserPlaylists(playlists) {
            this.userPlaylists = playlists
        },
        setIsAddingToPlaylistLoading(value) {
            this.isAddingToPlaylistLoading = value
        }
    },
  })