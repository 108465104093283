<script setup>
import { defineProps } from 'vue';
import { useModeStore } from '../store/mode.js';
import { getStrokeColor } from '../utils/commonFunctions.js';

const props = defineProps({
    breadcrumbs: {
        type: Array,
        default: () => [],
    },
    from: String,
    to: String,
});

const strokeColor = getStrokeColor();
const modeStore = useModeStore();

const getBreadcrumbs = () => {
    if (props.breadcrumbs.length > 0) {
        return props.breadcrumbs;
    } else if (props.from && props.to) {
        return [{ text: props.from }, { text: props.to }];
    } else {
        return [];
    }
};

const goToHome = () => {
    window.location.href = '/';
};
</script>

<template>
    <div class="flex text-sm py-2 px-[30px]">
        <template v-for="(breadcrumb, index) in getBreadcrumbs()" :key="index">
            <router-link
                v-if="breadcrumb.to"
                :to="breadcrumb.to"
                :class="{
                    'text-gray-400': index !== getBreadcrumbs().length - 1,
                    'text-gray-500': modeStore.selectedMode === 'light' && index === getBreadcrumbs().length - 1,
                    'text-gray-300': modeStore.selectedMode === 'dark' && index === getBreadcrumbs().length - 1,
                }"
            >
                <div v-if="breadcrumb.text === 'Laman Utama'" @click="goToHome()" class="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M16.5 15.9371H15.75V7.48455C15.75 7.01955 15.54 6.58455 15.1725 6.29955L9.9225 2.21955C9.3825 1.79205 8.6175 1.79205 8.0775 2.21955L2.8275 6.29955C2.46 6.58455 2.25 7.01955 2.25 7.47705L2.2125 15.9371H1.5C1.1925 15.9371 0.9375 16.1846 0.9375 16.4996C0.9375 16.8071 1.1925 17.0621 1.5 17.0621H16.5C16.8075 17.0621 17.0625 16.8071 17.0625 16.4996C17.0625 16.1846 16.8075 15.9371 16.5 15.9371ZM12.75 15.9371H5.25V10.4995C5.25 9.87705 5.7525 9.37455 6.375 9.37455H11.625C12.2475 9.37455 12.75 9.87705 12.75 10.4995V15.9371Z" fill="#9CA3AF"/>
                    </svg>
                </div>
                <div v-else>{{ breadcrumb.text }}</div>
            </router-link>
            <div
                v-else
                :class="{
                    'text-gray-400': index !== getBreadcrumbs().length - 1,
                    'text-gray-500': modeStore.selectedMode === 'light' && index === getBreadcrumbs().length - 1,
                    'text-gray-300': modeStore.selectedMode === 'dark' && index === getBreadcrumbs().length - 1,
                }"
            >
                <!-- {{ breadcrumb.text }} -->
                <!-- <div v-if="breadcrumb.text === 'Laman Utama'" @click="goToHome()" class="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M16.5 15.9371H15.75V7.48455C15.75 7.01955 15.54 6.58455 15.1725 6.29955L9.9225 2.21955C9.3825 1.79205 8.6175 1.79205 8.0775 2.21955L2.8275 6.29955C2.46 6.58455 2.25 7.01955 2.25 7.47705L2.2125 15.9371H1.5C1.1925 15.9371 0.9375 16.1846 0.9375 16.4996C0.9375 16.8071 1.1925 17.0621 1.5 17.0621H16.5C16.8075 17.0621 17.0625 16.8071 17.0625 16.4996C17.0625 16.1846 16.8075 15.9371 16.5 15.9371ZM12.75 15.9371H5.25V10.4995C5.25 9.87705 5.7525 9.37455 6.375 9.37455H11.625C12.2475 9.37455 12.75 9.87705 12.75 10.4995V15.9371Z" fill="#9CA3AF"/>
                    </svg>
                </div>
                <div v-else>{{ breadcrumb.text }}</div> -->
                {{ breadcrumb.text }}
            </div>
            <svg
                v-if="index !== getBreadcrumbs().length - 1"
                class="mx-2"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.56836 12.9498L9.64336 8.8748C10.1246 8.39355 10.1246 7.60605 9.64336 7.1248L5.56836 3.0498"
                    :stroke="strokeColor"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </template>
    </div>
</template>